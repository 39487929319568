<template>
  <v-row>
    <v-col cols="12" NewEditDeleteDialog>
      <v-dialog v-model="dialog" persistent max-width="1000">
        <v-card :loading="loading" v-if="!deleting">
          <v-card-title :class="`${arabic?'arFont':'enFont'} font-weight-bold`" style="font-size:22px;">
            <div>{{ i18n[resourceSettings.resourceName]  + ' | ' }}<span class="font-weight-light">{{ newResource.id ? i18n.edit : i18n.add }}</span></div>
          </v-card-title>
          <v-card-text class="pt-4">
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  :label="i18n.name"
                  outlined
                  dense
                  :class="`${arabic?'arFont':'enFont'}`"
                  v-model="newResource.name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  :items="statuses"
                  :label="i18n.name"
                  outlined
                  dense
                  :class="`${arabic?'arFont':'enFont'}`"
                  v-model="newResource.status"
                >
                </v-select>
              </v-col>
              
            </v-row>
          </v-card-text>
          <v-card-title :class="`${arabic?'arFont':'enFont'} font-weight-bold`" style="font-size:22px;">
            <div>{{ i18n[resourceSettings.resourceName]  + ' | ' }}<span class="font-weight-light">{{ i18n.questions }}</span></div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="elevation-1 mb-1" v-for="(question, q) in newResource.survey_questions" :key="'question-' + q">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      :label="i18n.label"
                      outlined
                      hide-details
                      dense
                      :class="`${arabic?'arFont':'enFont'}`"
                      v-model="question.label"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="types"
                      :label="i18n.question_type"
                      outlined
                      hide-details
                      item-value="value"
                      dense
                      :class="`${arabic?'arFont':'enFont'}`"
                      v-model="question.type"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="12" v-if="question.type == 'multiple'">
                    Answers
                  </v-col>
                  <v-col cols="12" md="12" v-if="question.type == 'multiple'">
                    <v-row>
                      <v-col cols="12" md="12"  v-for="(option, qo) in question.survey_question_options" :key="'survey_question_options-' + qo">
                        <v-text-field
                          :label="i18n.label"
                          hide-details
                          outlined
                          dense
                          :class="`${arabic?'arFont':'enFont'}`"
                          v-model="option.label"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" class="text-right">
                        <v-btn color="success" text @click.native="question.survey_question_options.push({})" :class="`${arabic?'arFont':'enFont'}`">{{i18n.add}}</v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="12" md="12" class="py-2">
                <v-btn color="success" text @click.native="newResource.survey_questions.push({survey_question_options:[]})" :class="`${arabic?'arFont':'enFont'}`">{{i18n.add}}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.native="closeDialog()" :class="`${arabic?'arFont':'enFont'}`">{{i18n.cancelBtn}}</v-btn>
            <v-btn 
              color="success" 
              v-if="!newResource.id"
              :disabled="!validate(newResource)"
              text @click.native="postResource(newResource)" 
              :class="`${arabic?'arFont':'enFont'}`"
            >{{i18n.submitBtn}}</v-btn>
            <v-btn 
                color="success" 
                v-if="newResource.id"
                :disabled="!validate(newResource)"
                text @click.native="updateResource(newResource)" 
                :class="`${arabic?'arFont':'enFont'}`"
              >{{i18n.updateBtn}}</v-btn>
          </v-card-actions>
        </v-card>
        <v-card :loading="loading" v-if="deleting">
          <v-card-title :class="`${arabic?'arFont':'enFont'} font-weight-bold`" style="font-size:22px;">
            <div>{{ i18n[resourceSettings.resourceName]  + ' | ' }}<span class="font-weight-light">{{i18n.delete}}</span></div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="pa-5 error white--text" :class="`${arabic ? 'arFont' : 'enFont'}`">
                {{i18n.areYouSureOfDelete.replace('|item|', deleteResrouceName )}}
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click.native="closeDialog()" :class="`${arabic?'arFont':'enFont'}`">{{i18n.cancelBtn}}</v-btn>
            <v-btn 
                color="error" 
                v-if="newResource.id"
                text @click.native="deleteResource(newResource)" 
                :class="`${arabic?'arFont':'enFont'}`"
              >{{i18n.deleteBtn}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { getRandomInt, randomString, getCDate, check, sleep } from '@/customFunction';
import { firebase, store, db, auth, storageRef } from '@/fb';

export default {
  components: {
    
  },
  props:['dialog', 'deleting', 'resourceKeys', 'newResource', 'loading', 'resourceSettings', 'statuses', 'types'],
  data(){
    return {
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      datepicker:{},
      timepicker:{},
      menu:{},
      uploadProgress:null,
      uploadFiled:null,
      file:null,

    }
  },
  methods: {
    getNameID(){
      return randomString(24, '#Aa') + `${new Date().getTime()}`
    },
    validate(newResource, notify) {
      let fsr = this.resourceKeys.filter(x=>x.required)
      let fse = this.resourceKeys.filter(x=>x.email)
      let v = true
      fsr.forEach(x=>{
        if(!newResource[x.name]) {
          v = false
          if(notify)
          this.$emit('snackbarNotification', `${this.i18n[x.name]} ${this.i18n.required}`, 'error')
        }
      })
      fse.forEach(x=>{
        if(!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(newResource[x.name])) {
          v = false
          if(notify)
          this.$emit('snackbarNotification', `${this.i18n[x.name]} ${this.i18n.valid_email}`, 'error')
        }
      })
      return v
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode != 46 && charCode != 45 && charCode != 46 &&
      !(charCode >= 48 && charCode <= 57)) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    goTo(path) {
      this.$router.push(path).catch(x=>{})
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    deleteResource(newResource) {
      this.$emit('deleteResource', newResource)
    },
    updateResource(newResource) {
      this.$emit('updateResource', newResource)
    },
    postResource(newResource) {
      this.$emit('postResource', newResource)
    },
    pickFileImages(name){
      // console.log(this.$refs)
      this.uploadFiled = name
      this.$refs.fileImages[0].click()
    },
    loadFileImages(file, type, ext){
      var file = file
      var metadata = {
        contentType: type
      };
      var uploadTask = storageRef.child('images/J_B_E_' + randomString(16, 'Aa#') + '.' + ext).put(file, metadata);
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot)=> {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					  this.uploadProgress = true
          }, (error) =>{
          // console.log(error);

        switch (error.code) {

          case 'storage/unauthorized':
            break;
          case 'storage/canceled':
            break;
          case 'storage/unknown':
            break;
        }
      },() => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
					this.uploadProgress = false
          this.$emit('getUpload', {field:this.uploadFiled, value:downloadURL} )
          // console.log(downloadURL)
        });
      });
    },
    onFilePickedImages (e) {
      const files = e.target.files
      if(files[0] !== undefined) {
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.file = files[0] // this is an image file that can be sent to server...
          this.loadFileImages(this.file, this.file.type, this.file.name.split('.').pop())
        })
      } 
    },
  },
  computed:{
    deleteResrouceName() {
      if (this.newResource.name) return this.newResource.name

      if (this.newResource.label) return this.newResource.label

      return ''
    },
    api(){
      return this.$store.state.settings.apiBase
    },
    language(){
      return this.$store.state.language
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
  },
  mounted(){
    
  }
}
</script>

<style>

</style>